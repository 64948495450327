import { gql } from '@apollo/client';
import {
  MitemStatus,
  SprintProgressTableCalculations__User2Fragment,
  SprintProgressTableSkaTimeStatusFragment,
} from '../../../../../../../../../generated/graphql';
import { groupBy } from '../../../../../../../../../services/groupBy';
import { toMap } from '../../../../../../../../../services/toMap';
import {
  calculateDoneVsPlan,
  filterExpectedToBeDoneSkas,
  groupByStatus,
} from '../../../../../../../common/utils/teamPageHelpers';

const getNumberOfActivitiesForUser = (
  sprint: SprintProgressTableSkaTimeStatusFragment[],
  userId: string
) =>
  sprint.filter((ska) => {
    return ska.owner.id === userId;
  }).length;

//Checking if typescript is lying, it can be undefined!!!!
const getCount = (skas: any[] | undefined) => {
  if (skas === undefined) {
    return 0;
  }
  return skas.length;
};

const calculateUserActivities = (
  sprintKeyActivities: SprintProgressTableSkaTimeStatusFragment[],
  teamMembersGroup: Record<
    string,
    SprintProgressTableCalculations__User2Fragment
  >
) => {
  const teamMembersWithAllStatusActivities: Record<
    string,
    {
      user: SprintProgressTableCalculations__User2Fragment;
      totalActivities: number;
      completedActivities: number;
      overdueActivities: number;
      upcomingActivities: number;
    }
  > = {};

  const skasByStatus = groupByStatus(
    sprintKeyActivities,
    Object.values(MitemStatus)
  );

  const completedSkasByUser = groupBy(
    skasByStatus[MitemStatus.COMPLETED],
    (ska) => ska.owner.id
  );
  const plannedSkasByUser = groupBy(
    skasByStatus[MitemStatus.PLANNED],
    (ska) => ska.owner.id
  );
  const overdueSkasByUser = groupBy(
    skasByStatus[MitemStatus.OVERDUE],
    (ska) => ska.owner.id
  );
  const activeSkasByUser = groupBy(
    skasByStatus[MitemStatus.ACTIVE],
    (ska) => ska.owner.id
  );

  Object.entries(teamMembersGroup).map(
    ([userId, user]) =>
      (teamMembersWithAllStatusActivities[userId] = {
        user,
        totalActivities: getNumberOfActivitiesForUser(
          sprintKeyActivities,
          userId
        ),
        completedActivities: getCount(completedSkasByUser[userId]),
        overdueActivities: getCount(overdueSkasByUser[userId]),
        upcomingActivities:
          getCount(activeSkasByUser[userId]) +
          getCount(plannedSkasByUser[userId]),
      })
  );

  return teamMembersWithAllStatusActivities;
};

const calculateSkasExpectedToBeDone = (
  filteredSkasExpectedToBeDone: SprintProgressTableSkaTimeStatusFragment[],
  teamMembersWithAllStatusActivities: Record<
    string,
    {
      user: SprintProgressTableCalculations__User2Fragment;
      totalActivities: number;
      completedActivities: number;
      overdueActivities: number;
      upcomingActivities: number;
    }
  >
) => {
  const teamMembersWithSkasExpectedToBeDone: Record<
    string,
    {
      user: SprintProgressTableCalculations__User2Fragment;
      totalActivities: number;
      completedActivities: number;
      overdueActivities: number;
      upcomingActivities: number;
      totalSkasExpectedToBeDone: number;
    }
  > = {};

  Object.entries(teamMembersWithAllStatusActivities).map(
    ([key, val]) =>
      (teamMembersWithSkasExpectedToBeDone[key] = {
        ...val,
        totalSkasExpectedToBeDone: getNumberOfActivitiesForUser(
          filteredSkasExpectedToBeDone,
          key
        ),
      })
  );

  return teamMembersWithSkasExpectedToBeDone;
};

const calculateTeamMembersDoneVsPlan = (
  teamMembersWithAllStatusActivities: Record<
    string,
    {
      user: SprintProgressTableCalculations__User2Fragment;
      totalActivities: number;
      completedActivities: number;
      overdueActivities: number;
      upcomingActivities: number;
      totalSkasExpectedToBeDone: number;
    }
  >
) => {
  const teamMembersWithDoneVsPlan: Record<
    string,
    {
      user: SprintProgressTableCalculations__User2Fragment;
      totalActivities: number;
      completedActivities: number;
      overdueActivities: number;
      upcomingActivities: number;
      totalSkasExpectedToBeDone: number;
      doneVsPlan: number | null;
    }
  > = {};

  Object.entries(teamMembersWithAllStatusActivities).map(
    ([key, val]) =>
      (teamMembersWithDoneVsPlan[key] = {
        ...val,
        doneVsPlan:
          val.totalActivities === 0
            ? null
            : calculateDoneVsPlan(
                val.completedActivities,
                val.totalSkasExpectedToBeDone
              ),
      })
  );

  return teamMembersWithDoneVsPlan;
};

export const getTeamMembersData = (
  sprintKeyActivities: SprintProgressTableSkaTimeStatusFragment[],
  teamMembers: SprintProgressTableCalculations__User2Fragment[]
) => {
  const teamMembersGroup = toMap(teamMembers, (user) => user.id);

  const teamMembersWithActivities = calculateUserActivities(
    sprintKeyActivities,
    teamMembersGroup
  );

  const filteredSkasExpectedToBeDone =
    filterExpectedToBeDoneSkas(sprintKeyActivities);

  const teamMemberWithSkasExpectedToBeDone = calculateSkasExpectedToBeDone(
    filteredSkasExpectedToBeDone,
    teamMembersWithActivities
  );

  const teamMemberData = calculateTeamMembersDoneVsPlan(
    teamMemberWithSkasExpectedToBeDone
  );

  const completeTeamData = Object.values(teamMemberData).map((val) => ({
    ...val,
  }));

  return completeTeamData;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_PROGRESS_TABLE_CALCULATIONS__USER2_FRAGMENT = gql`
  fragment SprintProgressTableCalculations__User2 on User2 {
    id
    domainId {
      itemId
    }
    name
    email
    displayName
    initials
  }
`;
