import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTeamMembersForTeamContributionsDocument } from '../../../../../../../../../generated/graphql';
import { DisplayName } from '../../../../../../../../../components/DisplayName';

type TeamMember = {
  id: string;
  name: string | null;
  displayName: string | null;
  email: string;
  initials: string;
};

interface Props {
  teamId: string;

  contributingMembers?: TeamMember[];
  setFileredTeamMember: (userId: string | 'ALL_MEMEMBERS') => void;
  className?: string;
}

export const TeamContributingMemberFilter = ({
  contributingMembers,
  setFileredTeamMember,
  className,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { data: membersData } = useQuery(
    GetTeamMembersForTeamContributionsDocument,
    { variables: { teamId } }
  );
  const nonContributors = membersData?.team2.members
    .filter(
      (m) =>
        !contributingMembers?.some((cu) => cu.id === m.user.domainId.itemId)
    )
    .map((fu) => fu.user);

  const { Option } = Select;

  return (
    <div
      className={className}
      data-intercom-target="Previous Period Summary - team member filter"
    >
      <Select
        defaultValue={'ALL_MEMEMBERS'}
        onChange={setFileredTeamMember}
        style={{ width: '220px' }}
      >
        <Option value={'ALL_MEMEMBERS'}>
          {t('TeamContributingMemberFilter.allMembers')}
        </Option>
        {contributingMembers?.map((o) => {
          return (
            <Option key={o.id} value={o.id}>
              {o.name}
            </Option>
          );
        })}
        {nonContributors?.map((nc) => {
          return (
            <Option key={nc.id} value={nc.id} disabled>
              <DisplayName user={nc.data} />
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_CONTRIBUTING_MEMBERS = gql`
  query getTeamMembersForTeamContributions($teamId: ID!) {
    team2(teamId: $teamId) {
      id
      members {
        user {
          id
          domainId {
            itemId
          }
          data {
            id
            name
            displayName
            email
          }
        }
      }
    }
  }
`;
