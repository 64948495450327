import { Alert, Layout } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompanyPageRoutes } from './appPages/company/CompanyPage.routes';
import { TeamPage } from './appPages/team/Team.page';
import { MyPage } from './appPages/myPage/MyPage.page';
import { GenericWhyPage } from './appPages/whyPage/GenericWhyPage';
import { GlobalNav } from './components/Navigation/GlobalNav';
import { envConf } from './envConf';
import { useTenantDetails } from './hooks/useTenantDetails';
import { ArchiveFilledIcon } from './icons/ArchiveIcon';
import { ErrorAlert } from './components/ErrorAlert';
import { TeamOverviewPage } from './appPages/team/overview/TeamOverview.page';

const WHY_PAGE_PATH = '/why';

export const CompanyRoutes = () => {
  const { Content } = Layout;
  const { t } = useTranslation();

  const { tenant, error } = useTenantDetails();
  const archived = tenant?.archived;

  const WhyPage = useWhyPage(tenant?.domainId.tenantId);

  useIntercomWhyPageTracking(WhyPage != null);
  return (
    <Layout style={{ minWidth: 1080, minHeight: '100%' }}>
      <GlobalNav hasWhy={WhyPage != null} />
      <Layout>
        {archived && (
          <Alert
            className="ma"
            icon={<ArchiveFilledIcon style={{ fontSize: 50, color: 'grey' }} />}
            type="error"
            message={t('App.archivedTenant.title')}
            showIcon
          />
        )}

        <ErrorAlert error={error} />
        <Content className="relative">
          <Switch>
            {/* <Route path="/admin">{isAdmin && <AdminPages />}</Route> */}
            <Redirect exact from="/" to="company/acceleration-board" />
            <Redirect
              exact
              from="/acceleration-board"
              to="/company/acceleration-board"
            />
            <Redirect
              exact
              from="/acceleration-board/performance"
              to="/company/acceleration-board"
            />
            <Redirect
              exact
              from="/company/acceleration-board/performance"
              to="/company/acceleration-board"
            />
            <Route path="/my-page">
              <MyPage />
            </Route>
            <Route path="/team/:teamId">
              <Suspense
                fallback={
                  <div className="content">
                    <TeamOverviewPage.Skeleton />
                  </div>
                }
              >
                <TeamPage />
              </Suspense>
            </Route>
            <Route path="/company">
              <CompanyPageRoutes
                tenantName={tenant?.name}
                initiativesEnabled={tenant?.initiativesEnabled}
                businessPlanEnabled={tenant?.businessPlanEnabled}
              />
            </Route>

            <Route path={WHY_PAGE_PATH}>
              {WhyPage ? <WhyPage /> : <GenericWhyPage />}
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

const useWhyPage = (tenantId?: string) => {
  const [WhyPage, setWhyPage] = useState<any>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const overrideTenantId = urlParams.get('whyPageId');
    const overrideEnvironment = urlParams.get('whyPageEnv');

    let environment = envConf.APP_ENV;
    let whyPageFilename = envConf.APP_ENV === 'demo' ? 'DemoWhyPage' : tenantId;

    if (overrideEnvironment && overrideTenantId) {
      environment = overrideEnvironment;
      whyPageFilename = overrideTenantId;
    }

    if (whyPageFilename && envConf.WHY_PAGE_DISABLED !== 'true') {
      import(`./appPages/whyPage/${environment}/${whyPageFilename}.tsx`)
        .then((component) => setWhyPage(component.Why))
        .catch(() => {
          /* fail silently if there is no why page */
        });
    }
  }, [tenantId]);

  return WhyPage;
};

const useIntercomWhyPageTracking = (hasWhyPage: boolean) => {
  const { pathname } = window.location;

  useEffect(() => {
    if (hasWhyPage && pathname === WHY_PAGE_PATH && envConf.INTERCOM_KEY) {
      (window as any)?.Intercom('update', {
        last_seen_on_company_why_page_at: Math.floor(Date.now() / 1000),
      });
    }
  }, [pathname, hasWhyPage]);
};
